<template>
    <v-card class="rounded" :loading="loading || loadingObjectives || loadingWorkingDays" :loader-height="7">
        <div class="light-grey w-full h-2"></div>
        <div class="py-4 px-6">
            <div v-if="amount && amount.aggregate" class="flex justify-between">
                <div class="flex flex-col justify-between">
                    <h6 class="text-medium-grey font-bold text-lg uppercase mb-4">{{ new Date().getFullYear() }}</h6>
                    <div>
                        <v-label>Valoare vanzari (incasate)</v-label>
                        <p class="text-2xl text-black font-bold mb-0">{{ $options.filters.formatRon(amount.aggregate) }}
                        </p>
                    </div>
                </div>
                <AnnualObjective :annual-amount-objective="annualAmountObjective" :annual-objective="annualObjective"
                    :size="100" />
            </div>
            <div v-else>
                <h6 class="text-medium-grey font-bold text-lg uppercase mb-4">{{ new Date().getFullYear() }}</h6>
                <v-label>Nu sunt cifre pentru anul curent</v-label>
            </div>
        </div>
    </v-card>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import AnnualObjective from "@/components/Setari/Objectives/AnnualObjective"

export default {
    name: "UserProductieAnnualStats",
    components: {
        AnnualObjective,
    },
    props: {
        userId: Number,
        yearFirstDay: String
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapState('userss', {
            loading: state => state.loadingAgentAnnualAggregate,
        }),
        ...mapState('objectives', {
            loadingObjectives: state => state.loadingAllFenceObjectives,
            loadingWorkingDays: state => state.loadingWorkingDays,
        }),
        ...mapGetters('userss', ['getAgentAnnualAggregate']),
        amount() {
            return this.getAgentAnnualAggregate(this.userId)
        },
        ...mapGetters('objectives', {
            getObjectives: 'getAllSelectedUserObjectives',
            getWorkingDays: 'getAllYearWorkingDays'
        }),
        currentYearFirstDay() {
            return new Date(this.yearFirstDay)
        },
        objectives() {
            return this.getObjectives
        },
        workingDays() {
            return this.getWorkingDays
        },
        annualAmountObjective() {
            if (this.workingDays && this.objectives.length) {
                let sum = 0
                const wDaysArr = Object.values(this.workingDays)
                const daysArr = wDaysArr.filter((n, idx) => { return idx !== 0 })
                daysArr.forEach((monthDays, idx) => {
                    // use index in daysArr for identifying the month and compare dates to find objective
                    const monthFirstDay = new Date(this.currentYearFirstDay.getFullYear(), idx, 1).setHours(0, 0, 0, 0)
                    const objective = this.objectives.find(objective => new Date(objective.date).setHours(0, 0, 0, 0) <= monthFirstDay)
                    sum += objective ? Number(objective.amount) * Number(monthDays) : 0
                })
                return sum
            }
        },
        annualObjective() {
            const objective = this.amount.aggregate / this.annualAmountObjective * 100
            return objective ? Math.floor(objective) : 0
        },
    },
    watch: {
        userId(newVal, oldVal) {
            if (newVal != oldVal) {
                this.loadUserAllFenceObjectives([])
                this.fetchObjectives()
            }
        }
    },
    methods: {
        ...mapMutations('objectives', ["loadUserAllFenceObjectives"]),
        ...mapActions('objectives', ["fetchUserAllFenceObjectives", "fetchWorkingDaysByYear"]),
        async fetchObjectives() {
            await this.fetchUserAllFenceObjectives({ item: { user_id: this.userId } })
        }
    },
    async created() {
        await this.fetchWorkingDaysByYear(this.currentYearFirstDay.getFullYear())
        await this.fetchObjectives()
    }
}

</script>